import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAppTarget, getHost } from "../../utils/Navigation/Host";

const isProd = true;
const CAS_STATUS_IN_PROCESS = 'in_process';

// GAR
const GAR_ENDPOINT = "idp-auth.gar.education.fr";
const GAR_PREPROD_ENDPOINT = "idp-auth.partenaire.test-gar.education.fr";

// CANOPE
const CANOPE_ENDPOINT = isProd === true ? "www.reseau-canope.fr/cas-usagers" : "val.reseau-canope.fr/cas-usagers";

export const getTicket = () => {
    return getParamWithName("ticket");
}

export const getGARLoginUrl = () => {
    const appTarget = getAppTarget();
    const host = window.location.hostname;
    if (appTarget === "canope") {
        return "https://" + CANOPE_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    } else if (appTarget === "gar-prod") {
        return "https://" + GAR_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    } else if (appTarget === "gar-preprod") {
        return "https://" + GAR_PREPROD_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    }
}

export const getStatus = () => {
    return getParamWithName("status");
}

const getParamWithName = (name: string) => {
    return new URL(window.location.href).searchParams.get(name);
}

export const redirectToLoginUrl = async () => {
    window.location.href = getGARLoginUrl();
}

const getValidateUrl = (ticket: string) => {
    const appTarget = getAppTarget();
    const hostToUse = appTarget === "gar-prod" ? "https://app-tne.lili.cool" : appTarget === "gar-preprod" ? "https://app-tne-pp.lili.cool" : "https://app-tne-demo.lili.cool";
    const baseUrl = appTarget === "canope" ? hostToUse + "/cas_tne_proxy" : hostToUse + "/cas_proxy";
    const service = window.location.href;
    const queryParams = {
        service,
        ticket,
        format: "json"
    };

    const path = 'p3/serviceValidate';

    let validatingUrl = baseUrl + "/" + path + "?";
    let params = "";
    for (const aParamKey in queryParams) {
        if (Object.hasOwnProperty.call(queryParams, aParamKey)) {
            if (params.length > 0) {
                params = params + "&"
            }
            const aParam = queryParams[aParamKey];
            if (aParamKey === "service") {
                params = params + "service=" + encodeURI(hostToUse);
            } else {
                params = params + aParamKey + "=" + aParam;
            }
        }
    }
    validatingUrl = validatingUrl + params;

    return validatingUrl;
};

export const validateTicket = async (ticket: string) => {
    const appTarget = getAppTarget();
    const navigatorHost:string = getHost();
    if ((navigatorHost === "localhost") || (navigatorHost === "lili-dev-608f0.web.app")) {
        const userData = {
            user: {
                IDO: ["localhost_05678"],
                PRO: ["lili"],
                P_MS2: 0,
                UAI: ["0180897C"],
                P_MEL: ["axel@lili.cool"],
                NOM: ["de Sainte Marie"],
                PRE: ["Axel"]
            },
            ticket: "12345"
        }
        sessionStorage.setItem("cas", JSON.stringify({ userData: userData, appTarget }));
    } else {
        let validateURL = getValidateUrl(ticket);
        const content_type = 'application/json';
        try {
            const mode = appTarget === "canope" ? "cors" : "same-origin";
            const validationRequestResponse = await fetch(validateURL, {
                mode,
                credentials: "include",
                headers: {
                    'Content-Type': content_type
                }
            });
            const validationResponseAsText = await validationRequestResponse.text();
            //On va supprimer la valeur du dernier referrer
            await AsyncStorage.removeItem("lastReferrer");
            let validationResponseAsJson = JSON.parse(validationResponseAsText);
            if (validationResponseAsJson.serviceResponse) {
                if (validationResponseAsJson.serviceResponse.authenticationSuccess) {
                    let user = validationResponseAsJson.serviceResponse.authenticationSuccess.attributes;
                    sessionStorage.setItem("cas", JSON.stringify({ userData: { user, ticket }, appTarget }));
                    return ({ user, ticket });
                } else {
                    throw ("unable to validate ticket : "+JSON.stringify(validationResponseAsJson));
                }
            }
        } catch (error) {
            throw (error);
        }
    }
}

export const checkCASAuthentication = async () => {
    try {
        //On va d'abord vérifier la présence d'un ticker dans l'URL
        const ticket = getTicket();
        const appTarget = getAppTarget();
        if (ticket === null) {
            // On va vérifier si l'URL contient un paramètre status. Si c'est la cas, cela peut signifier qu'il y a une erreur
            const status = getStatus();
            if (status === CAS_STATUS_IN_PROCESS) {
                throw new Error("No ticket found");
            } else {
                redirectToLoginUrl();
            }
        } else {
            const userData = await validateTicket(ticket);
            return userData;
        }
        return null;
    } catch (error) {
        throw error;
    };
}
